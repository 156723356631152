import * as Focus from '../common/focus';

window.addEventListener('load', function () {
  const toggleSearch = document.querySelector('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const closeSearch = document.querySelector('#search-close');
  const dropdowns = document.querySelectorAll('.nav-primary .dropdown');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    toggleSearch.setAttribute('aria-expanded', 'false');
    closeSearch.setAttribute('aria-expanded', 'false');

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.remove('lockedPosition');
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    toggleSearch.setAttribute('aria-expanded', 'true');
    closeSearch.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.add('lockedPosition');
    }
  }

  if (toggleSearch && closeSearch) {
    toggleSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      } else {
        openSearchModal();
      }
    });

    closeSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      }
    });

    // close search bar when dropdown opens from hover
    if (
      0 < dropdowns.length &&
      !searchModal.classList.contains('search-modal--window')
    ) {
      dropdowns.forEach(function (dropdown) {
        dropdown.addEventListener('mouseover', function () {
          if (searchModal.classList.contains('opened')) {
            closeSearchModal();
          }
        });
      });
    }
  }
});

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const modalForm = document.querySelector('.search-input-wrapper');
  let firstFocus = true;

  if (findstr && modalForm) {
    findstr.hooks.addAction(
      'searchResults',
      'modal-search',
      (results, group) => {
        if ('modal-search' === group) {
          if ('' === results.query) {
            modalForm.classList.remove('dropdown-active');
          } else {
            modalForm.classList.add('dropdown-active');
          }
        }
      }
    );

    modalForm.addEventListener('focusin', function () {
      if (firstFocus) {
        firstFocus = false;
      } else {
        modalForm.classList.add('dropdown-active');
      }
    });

    modalForm.addEventListener('focusout', function () {
      modalForm.classList.remove('dropdown-active');
    });
  }
});
