/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/sticky-navbar');
require('./main/listing-page-count');
require('./main/findstr-dropdowns');
require('./main/findstr-empty-results');
require('./main/clinic-locator');

import domReady from '@wordpress/dom-ready';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

domReady(() => {
  Fancybox.bind('[data-fancybox]', {});
});
