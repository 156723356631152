/* global google */
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const dropdown = document.querySelector('#clinic-locator-content');
  const toggle = document.querySelector('.clinic-locator__button');
  const locatorBtn = document.querySelector('#locator-trigger');
  const loader = dropdown.querySelector('.clinic-locator__loader');
  const clinicDetailsWrapper = dropdown.querySelector('#clinic-details');
  const clinicNameElem = clinicDetailsWrapper?.querySelector('.clinic-name');
  const clinicDistanceElem =
    clinicDetailsWrapper?.querySelector('.clinic-distance');
  const clinicDurationElem =
    clinicDetailsWrapper?.querySelector('.clinic-duration');
  const clinicHoursElem = clinicDetailsWrapper?.querySelector('.clinic-hours');
  const clinicAddressElem =
    clinicDetailsWrapper?.querySelector('.clinic-address');
  const clinicPhoneElem = clinicDetailsWrapper?.querySelector('.clinic-phone');
  const clinicBookingElem =
    clinicDetailsWrapper?.querySelector('.clinic-booking');
  const locatorWrapper = dropdown.querySelector(
    '.clinic-locator__dropdown__locator-wrapper'
  );

  const userLocation = getUserLocationFromSession();

  // Automatically show the closest clinic if already cached
  debounce(() => {
    if (userLocation) {
      const cachedClinic = getCachedClosestClinic();
      if (cachedClinic) {
        updateDropdownWithClinicDetails(cachedClinic);
      } else {
        // Show the locator button if no cached clinic is available
        locatorWrapper.style.display = 'block';
      }
    } else {
      // Show the locator button if no user location is available
      locatorWrapper.style.display = 'block';
    }
  }, 300)();

  toggle.addEventListener('click', () => {
    const isVisible = dropdown.classList.toggle('visible');
    toggle.setAttribute('aria-expanded', isVisible);
  });

  locatorBtn.addEventListener(
    'click',
    debounce(() => {
      if (userLocation) {
        const cachedClinic = getCachedClosestClinic();
        if (cachedClinic) {
          updateDropdownWithClinicDetails(cachedClinic);
        } else {
          fetchClinics(userLocation.latitude, userLocation.longitude);
        }
      } else {
        getUserLocation();
        if (loader) {
          loader.classList.add('visible');
        }
      }
    }, 300)
  );

  function getUserLocationFromSession() {
    const storedLocation = sessionStorage.getItem('userLocation');
    return storedLocation ? JSON.parse(storedLocation) : null;
  }

  function storeUserLocation({ latitude, longitude }) {
    sessionStorage.setItem(
      'userLocation',
      JSON.stringify({ latitude, longitude })
    );
  }

  function getCachedClosestClinic() {
    const cachedData = sessionStorage.getItem('cachedClosestClinic');
    return cachedData ? JSON.parse(cachedData) : null;
  }

  function cacheClosestClinic(closestClinicData) {
    sessionStorage.setItem(
      'cachedClosestClinic',
      JSON.stringify(closestClinicData)
    );
  }

  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          storeUserLocation({ latitude, longitude });
          fetchClinics(latitude, longitude);
        },
        (error) => console.error('Geolocation error:', error),
        { enableHighAccuracy: true }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  function fetchClinics(latitude, longitude) {
    const url = `/wp-json/clinic-locator/v1/closest-clinics?lat=${latitude}&lng=${longitude}`;
    fetch(url)
      .then((response) => response.json())
      .then((clinics) => {
        if (clinics.length) {
          clinics = clinics.filter(function (element) {
            return element.clinicLat !== '0' && element.clinicLong !== '0';
          });
          calculateDistancesUsingGoogleAPI(latitude, longitude, clinics);
        } else {
          console.log('No clinics found.');
        }
      })
      .catch((error) => console.error('Error fetching clinics:', error));
  }

  function calculateDistancesUsingGoogleAPI(userLat, userLng, clinics) {
    const service = new google.maps.DistanceMatrixService();

    const origins = [{ lat: parseFloat(userLat), lng: parseFloat(userLng) }];
    const destinations = clinics
      .map(({ clinicLat, clinicLong }) => ({
        lat: parseFloat(clinicLat),
        lng: parseFloat(clinicLong),
      }))
      .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng));

    service.getDistanceMatrix(
      {
        origins,
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') {
          response.rows[0].elements = response.rows[0].elements.filter(
            function (element) {
              return element.status === 'OK';
            }
          );

          const closestIndex = response.rows[0].elements.reduce(
            (closestIdx, elem, idx, elements) =>
              elem.distance.value < elements[closestIdx].distance.value
                ? idx
                : closestIdx,
            0
          );

          const closestClinic = clinics[closestIndex];
          const distance =
            response.rows[0].elements[closestIndex].distance.text;
          const duration =
            response.rows[0].elements[closestIndex].duration.text;
          const mapsLink = `https://www.google.com/maps/dir/${userLat},${userLng}/${closestClinic.clinicLat},${closestClinic.clinicLong}`;

          console.log(response);
          console.log(clinics);
          console.log(closestIndex);

          const closestClinicData = {
            closestClinic,
            distance,
            duration,
            mapsLink,
          };

          cacheClosestClinic(closestClinicData);
          updateDropdownWithClinicDetails(closestClinicData);
        } else {
          console.error('Error with Distance Matrix:', status, response);
        }
      }
    );
  }

  function updateDropdownWithClinicDetails({
    closestClinic,
    mapsLink,
    distance,
    duration,
  }) {
    if (!closestClinic) {
      console.error('Invalid clinic data:', closestClinic);
      return;
    }

    if (!clinicDetailsWrapper) {
      console.error('Clinic details wrapper not found.');
      return;
    }

    updateElementText(clinicNameElem, closestClinic.post_title);
    updateElementText(clinicDistanceElem, distance);
    updateElementText(clinicDurationElem, duration);
    updateElementHTML(clinicHoursElem, closestClinic.hours);
    updateElementHref(clinicAddressElem, mapsLink, closestClinic.address);
    updateElementHref(
      clinicPhoneElem,
      `tel:+1${closestClinic.phone_number.replace(/[^0-9]/g, '')}`,
      closestClinic.phone_number
    );
    updateElementHref(
      clinicBookingElem,
      closestClinic.online_appointment,
      null
    ); // Keep button text

    clinicDetailsWrapper.classList.add('visible');

    if (loader) {
      loader.classList.remove('visible');
    }

    if (locatorWrapper) {
      locatorWrapper.style.display = 'none';
    }
  }

  function updateElementText(element, text) {
    if (element) element.textContent = text;
  }

  function updateElementHTML(element, html) {
    if (element) element.innerHTML = html;
  }

  function updateElementHref(element, href, text = null) {
    if (element) {
      element.setAttribute('href', href);
      if (text !== null) {
        element.textContent = text;
      }
    }
  }

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }
});
