import { _nx, sprintf } from '@wordpress/i18n';

document.addEventListener('findstrLoaded', function () {
  function initItemsLabel(dropdown) {
    const label = document.createElement('p');
    const labelCount = document.createElement('span');
    label.classList.add('items-label');
    labelCount.classList.add('items-label__count');
    label.textContent = dropdown.control_input.getAttribute('placeholder');
    label.appendChild(labelCount);
    dropdown.control.appendChild(label);

    if (0 < dropdown.items.length) {
      labelCount.textContent = sprintf(
        /* translators: %s is number of options selected */
        _nx(
          ' (%s selected)',
          ' (%s selected)',
          dropdown.items.length,
          'items selected count',
          'vtx'
        ),
        dropdown.items.length
      );
    }
  }

  function updateItemsLabel(dropdown) {
    const labelCount = dropdown.control.querySelector('.items-label__count');

    if (labelCount) {
      if (0 < dropdown.items.length) {
        labelCount.textContent = sprintf(
          /* translators: %s is number of options selected */
          _nx(
            ' (%s selected)',
            ' (%s selected)',
            dropdown.items.length,
            'items selected count',
            'vtx'
          ),
          dropdown.items.length
        );
      } else {
        labelCount.textContent = '';
      }
    }
  }

  setTimeout(function () {
    const dropdowns = document.querySelectorAll(
      '.findstr-field-dropdown .tomselected'
    );

    if (0 < dropdowns.length) {
      dropdowns.forEach((dropdown) => {
        if (1 !== dropdown.tomselect.settings.maxItems) {
          initItemsLabel(dropdown.tomselect);
          dropdown.tomselect.on('item_add', function () {
            updateItemsLabel(dropdown.tomselect);
          });
          dropdown.tomselect.on('item_remove', function () {
            updateItemsLabel(dropdown.tomselect);
          });
        }
      });
    }
  }, 500);
});
