document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr || {};

  if (findstr) {
    findstr.hooks.addAction(
      'searchResults',
      'page-count',
      function (results, group) {
        const pageCountHTML = document.querySelector(
          '.listing-page-count[data-group="' + group + '"'
        );
        if (pageCountHTML) {
          let totalPages = 1;

          if (0 !== results.totalPages) {
            totalPages = results.totalPages;
          }
          pageCountHTML.querySelector('.current-page').textContent =
            results.page;
          pageCountHTML.querySelector('.total-page').textContent = totalPages;
        }
      }
    );
  }
});
