document.addEventListener('findstrLoaded', () => {
  const { findstr, findstrNoResultsData: noResultsData } = window;

  if (!findstr || !noResultsData) {
    console.error(
      !findstr
        ? 'findstr object is not defined.'
        : 'findstrNoResultsData is not defined.'
    );
    return;
  }

  // Define the filter to modify the empty results data
  findstr.hooks.addFilter(
    'findstrEmptyResultsData',
    'findstr-search',
    (data) => ({
      ...data,
      ctas: noResultsData.ctas || [], // Custom CTAs
      cta_sentence: noResultsData.cta_sentence || '', // CTA sentence
      emptyResultsText: 'No results found. Please try again.', // Default message
    })
  );
});
