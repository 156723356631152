import domReady from '@wordpress/dom-ready';

domReady(() => {
  const sticky = 80;
  let timer = null;

  function stickyNavbar() {
    const st = window.scrollY;
    if (st >= sticky) {
      document.body.classList.add('sticky-navbar');
    } else {
      document.body.classList.remove('sticky-navbar');
    }
  }

  window.addEventListener('scroll', () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      // actual callback
      stickyNavbar();
    }, 50);
  });

  stickyNavbar();
});
